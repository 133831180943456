<template>
  <div>
    <div class="flix-form-group" v-if="$store.getters.assistents.length">
      <b>{{ $t('message.pleaseUseBookingForm') }}:</b>
      <ol class="flix-breadcrumb">
        <li class="flix-html-li" v-if="linkAll">
          <router-link @click.native="setAssistent(false)" :to="{name: linkAll}" class="flix-html-a" :class="{'flix-html-strong': !$route.params.id}"><flixIcon :id="'calendar'" /> {{ $t('message.allCalendar') }}</router-link>
        </li>
        <li class="flix-html-li" v-for="(assistent, index) in $store.getters.assistents" :key="assistent.ID">
          <router-link @click.native="setAssistent(assistent)" :to="{name: link, params: { id: assistent.ID }}" class="flix-html-a" :style="{color: assistent.color || $store.getters.colors[index], 'border-color': assistent.color || $store.getters.colors[index]}" :class="{'flix-html-strong': assistent.ID == $route.params.id}"><flixIcon :id="'calendar'" /> {{ assistent.title }}</router-link>
        </li>
      </ol>
    </div>
    <div v-else>
      <div class="flix-form-group">
        <div class="flix-alert flix-alert-warning">{{ $t('message.warningNullCalendar') }}</div>
      </div>
      <div class="flix-form-group">
        <router-link class="flix-btn flix-btn-info" :to="{name: 'dashboardAssistentEdit', params: {id: 'new'}}"><flixIcon id="plus" /> {{ $t('message.add', {name: $tc('message.calendar', 1)}) }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    link: String,
    linkAll: String,
    params: [Boolean, Object]
  },
  data () {
    return {

    }
  },
  methods: {
    setAssistent (assistent) {
      this.$store.commit('setActiveAssistent', assistent)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-breadcrumb
    padding-top: 0
    margin-top: 8px
  .flix-breadcrumb > .flix-html-li
    line-height: 2em
  .flix-breadcrumb > .flix-html-li + .flix-html-li:before
    content: " :|: "
  .flix-breadcrumb > .flix-html-li > a
    text-decoration: none
  .flix-breadcrumb > .flix-html-li > .flix-html-strong
    padding-bottom: 5px
    border-bottom-width: 3px
    border-bottom-style: dashed

  @media (max-width: 500px)
    .flix-breadcrumb
      > .flix-html-li
        display: block
        padding: 2px 0
        font-size: inherit

        + .flix-html-li:before
          content: ""
          padding: 0
</style>
